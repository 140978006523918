.SelectorMenu {
    position: relative;
    z-index: 4;
    background-color: #282c34;
}
.Option {
    padding: 0.5em 2em;
    cursor: pointer;
    display: inline-block;
    background-color: #282c34;
}
.Selected {
    background-color: mediumpurple;
    cursor: auto;
}