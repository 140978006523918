.ImageInput {
    display: flex;
    width: 100%;
    padding-bottom: 0.7em;
}
.ImageInput label {
    padding-right: 1em;
    padding-left: 0.5em;
}
.ImageInput input[type=text] {
    flex: 1;
}
.ImageInputs {
    padding-top: 0.5em;
    padding-bottom: 0.2em;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    background-color: #282c34;
    z-index: 4;
    position: relative;
}
@media only screen and (max-width: 768px) {
    span.ExtraText {
        display: none;
    }
}
.FlipInputs {
    flex-grow: 0;
    flex-basis: 2em;
    padding: 0 0.2em;
}
.InputFields {
    flex: 1;
}