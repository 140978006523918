.Container {
    width: 100%;
    position: static;
    background-color: #61dafb;
}
.ImgForeground {
    max-width: 100vw;
    max-height: 100vh;
    left: 0;
    position: absolute;
    opacity: 1;
}
.ImgForeground:hover {
    opacity: 0;
}
.ImgBackground {
    max-width: 100vw;
    max-height: 100vh;
    left: 0;
    position: absolute;
}