.Container {
    width: 100%;
    position: static;
    background-color: #61dafb;
    touch-action: none;
}
.ImgBackground {
    max-width: 100vw;
    max-height: 100vh;
    left: 0;
    position: absolute;
    z-index: 1;
}
.ForegroundWrapper {
    position: absolute;
    height: 100vh;
    z-index: 2;
}
.ForegroundSlider {
    overflow: hidden;
}
.ImgForeground {
    max-width: 100vw;
    max-height: 100vh;
    z-index: 2;
}
.SliderHint {
    width: 100%;
    height: 100px;
    margin-top: -50px;
    z-index: 3;
}