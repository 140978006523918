.Container {
    width: 100%;
    position: static;
    background-color: #61dafb;
    touch-action: none;
}
.BackgroundWrapper {
    max-width: 100vw;
    max-height: 100vh;
    left: 0;
    position: absolute;
}
.ImgForeground {
    max-width: 100vw;
    max-height: 100vh;
    left: 0;
    position: absolute;
}
.ImgBackground {
    max-width: 100vw;
    max-height: 100vh;
}
.Hint {
    width: 0;
    position: absolute;
}